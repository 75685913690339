import styles from "./ApprovalStatusBar.module.css";
import classNames from "classnames";
import { toPrintableDateFormat } from "../util/TimeUtils";

interface ApprovalStatusBarProps {
  offerId: string;
  approvalDate?: string;
  approvingContactName?: string;
}

export default function ApprovalStatusBar(props: ApprovalStatusBarProps) {
  let className: string;
  let content: string;

  if (props.approvalDate) {
    const dateString = toPrintableDateFormat(props.approvalDate);
    content = `הזמנה ${props.offerId} אושרה על ידי ${props.approvingContactName} בתאריך ${dateString}`;
    className = styles.approvedBar;
  } else {
    content = "הזמנה זו ממתינה לאישור";
    className = styles.notApprovedBar;
  }

  return (
    <div className={classNames(styles.approvalStatusBar, className)}>
      {content}
    </div>
  );
}
