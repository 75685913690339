import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./ApprovalDialog.module.css";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

export interface OfferApprovalDetails {
  approvingUserFirstName: string;
  approvingUserLastName: string;
  approvingUserPhone: string;
  approvingUserEmail: string;
  approvingUserRole: string;
  approvingOrganization: string;
  lectureDates: string;
}

interface ApprovalDialogProps {
  offerId: string;
  open: boolean;
  closeDialog: (approved: boolean) => void;
  onApproved: (approvalDetails: OfferApprovalDetails) => Promise<void>;
}

export default function ApprovalDialog(props: ApprovalDialogProps) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [org, setOrg] = useState("");
  const [role, setRole] = useState("");
  const [lectureDates, setLectureDates] = useState("");
  const [email, setEmail] = useState("");

  const [missingFields, setMissingFields] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onClick = () => {
    setError("");
    const missing = checkMissingFields();
    if (missing.length > 0) {
      return;
    }

    setLoading(true);
    props
      .onApproved({
        approvingUserFirstName: firstName,
        approvingUserLastName: lastName,
        approvingUserPhone: phone,
        approvingUserEmail: email,
        approvingUserRole: role,
        approvingOrganization: org,
        lectureDates: lectureDates,
      })
      .then(() => {
        setLoading(false);
        props.closeDialog(true);
      })
      .catch(() => {
        setError(
          "לא ניתן להשלים פעולה זו, נא ליצור קשר עם המשרד - 052-3366313",
        );
        setLoading(false);
      });
  };

  const checkMissingFields = () => {
    const missing = [];
    if (!firstName) missing.push("שם פרטי");
    if (!lastName) missing.push("שם משפחה");
    if (!phone) missing.push("טלפון");
    if (!org) missing.push("שם הארגון");
    if (!role) missing.push("תפקיד");
    if (!email) missing.push("דואר אלקטרוני");
    if (!lectureDates) missing.push("תאריך הרצאה");

    setMissingFields(missing);
    return missing;
  };

  return (
    <Dialog open={props.open} onClose={() => props.closeDialog(false)}>
      <DialogTitle>אישור הזמנת הרצאה</DialogTitle>
      <DialogContent>
        <DialogContentText>
          הרני לאשר את הזמנה מספר
          {` ${props.offerId} `}
          בתנאים המפורטים בהצעת המחיר
          <div className={styles.fieldsContainer}>
            <Field
              label="שם פרטי"
              value={firstName}
              onChange={setFirstName}
              missingFields={missingFields}
              setMissingFields={setMissingFields}
            />
            <Field
              label="שם משפחה"
              value={lastName}
              onChange={setLastName}
              missingFields={missingFields}
              setMissingFields={setMissingFields}
            />
            <Field
              label="טלפון"
              value={phone}
              onChange={setPhone}
              missingFields={missingFields}
              setMissingFields={setMissingFields}
            />
            <Field
              label="שם הארגון"
              value={org}
              onChange={setOrg}
              missingFields={missingFields}
              setMissingFields={setMissingFields}
            />
            <Field
              label="תפקיד"
              value={role}
              onChange={setRole}
              missingFields={missingFields}
              setMissingFields={setMissingFields}
            />
            <Field
              label="דואר אלקטרוני"
              value={email}
              onChange={setEmail}
              type="email"
              missingFields={missingFields}
              setMissingFields={setMissingFields}
            />
            <Field
              label="תאריך הרצאה"
              value={lectureDates}
              onChange={setLectureDates}
              missingFields={missingFields}
              setMissingFields={setMissingFields}
            />
          </div>
          <br />
          חיוב בשל ביטול הרצאה: בהתראה של פחות משבעה ימי עסקים - 50% מהעלות.
          <br />
          בהתראה של פחות משני ימי עסקים - 100% מהעלות.
          <br />
          {error && <div className={styles.error}> {error} </div>}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClick} color="primary" disabled={loading}>
          {loading ? <CircularProgress /> : "אשר הזמנה"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Field(props: {
  label: string;
  value: string;
  onChange: (newString: string) => void;
  missingFields: string[];
  setMissingFields: (fields: string[]) => void;
  type?: string;
}) {
  const missing = props.missingFields.includes(props.label);

  const onChange = (newValue: string) => {
    props.onChange(newValue);
    if (missing) {
      props.setMissingFields(
        props.missingFields.filter((x) => x !== props.label),
      );
    }
  };

  return (
    <div className={styles.field}>
      <TextField
        label={props.label}
        value={props.value}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        type={props.type}
        error={missing}
        helperText={missing ? "שדה חובה" : undefined}
      />
    </div>
  );
}
