import React from "react";
import styles from "./PrintablePage.module.css";
import Fab from "@mui/material/Fab";
import PrintIcon from "@mui/icons-material/Print";

interface PrintablePageProps {
  statusBar?: React.ReactNode;
  children: React.ReactNode;
}

export default function PrintablePage(props: PrintablePageProps) {
  return (
    <>
      <div className={styles.printableContainer}>
        <div className={styles.pageContent}>{props.children}</div>
      </div>

      <div className={styles.container}>
        <div className={styles.page}>
          <div className={styles.statusBar}>{props.statusBar}</div>

          <div className={styles.pageContent}>{props.children}</div>
        </div>
        <div className={styles.actionButtons}>
          <Fab
            variant="extended"
            size="large"
            color="primary"
            className={styles.actionButton}
            onClick={window.print}
          >
            <PrintIcon className={styles.actionButtonIcon} />
            הדפסה
          </Fab>
        </div>
      </div>
    </>
  );
}
