import { useLocation } from "react-router";
import Offer from "./Offer";
import { useEffect, useState } from "react";
import PageNotFound from "../components/PageNotFound";
import LoadingScreen from "./LoadingScreen";
import { OfferApprovalDetails } from "./ApprovalDialog";
import {
  VioletApi,
  OfferDetails,
  ApproveOfferRequest,
} from "../generated/OfferApi";

interface UrlParameters {
  offerId: string;
  lectureId: string;
  token: string;
}

enum FetchStatus {
  Fetching,
  Fetched,
  Failed,
}

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://func-violet-prod.azurewebsites.net/api"
    : "http://localhost:7071/api";

export default function OfferContainer() {
  const location = useLocation();
  const { lectureId, token } = parseUrlParams(location.search);

  const [offerData, setOfferData] = useState<OfferDetails | undefined>();
  const [fetchStatus, setFetchStatus] = useState(FetchStatus.Fetching);

  useEffect(() => {
    setFetchStatus(FetchStatus.Fetching);
    if (!lectureId || !parseInt(lectureId) || !token) {
      setFetchStatus(FetchStatus.Failed);
      return;
    }

    new VioletApi({ baseUrl: BASE_URL }).offers
      .getOfferDetails({ l: parseInt(lectureId), t: token })
      .then((res) => {
        if (res.data) {
          setOfferData(res.data);
          setFetchStatus(FetchStatus.Fetched);
        }
      })
      .catch((e) => {
        console.error(e);
        setFetchStatus(FetchStatus.Failed);
      });
  }, [lectureId, token, setFetchStatus]);

  const markAsApproved = (approvalDetails: OfferApprovalDetails) => {
    if (!offerData) return;

    setOfferData({
      ...offerData,
      approvingContactName:
        approvalDetails.approvingUserFirstName +
        " " +
        approvalDetails.approvingUserLastName,
      approvalTime: new Date().toISOString(), // Approved now.
    });
  };

  switch (fetchStatus) {
    case FetchStatus.Fetching:
      return <LoadingScreen />;

    case FetchStatus.Fetched:
      return (
        <Offer
          offerData={offerData!}
          onApproved={(approvalDetails: OfferApprovalDetails) =>
            onApproved(lectureId, token, approvalDetails).then(() =>
              markAsApproved(approvalDetails),
            )
          }
        />
      );

    case FetchStatus.Failed:
      return <PageNotFound />;
  }
}

async function onApproved(
  lectureId: string,
  token: string,
  approvalDetails: OfferApprovalDetails,
): Promise<any> {
  const request: ApproveOfferRequest = {
    ...approvalDetails,
    lectureId: parseInt(lectureId),
    token,
  };
  return new VioletApi({ baseUrl: BASE_URL }).offers.approveOffer(request);
}

function parseUrlParams(search: string): UrlParameters {
  const rawParams = search.replace("?", "").split("&");
  const extractedParams: { [key: string]: string } = {};
  rawParams.forEach((item) => {
    const itemPair = item.split("=");
    extractedParams[itemPair[0]] = itemPair[1];
  });

  return {
    offerId: extractedParams.o,
    lectureId: extractedParams.l,
    token: extractedParams.t,
  };
}
