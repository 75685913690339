import styles from "./Offer.module.css";
import ApprovalStatusBar from "./ApprovalStatusBar";
import PrintablePage from "../components/PrintablePage";
import HMLogo from "../components/HMLogo";
import ApprovalButton from "./ApprovalButton";
import { useEffect } from "react";
import { reportPageView } from "../util/AnalyticUtils";
import OfferFooter from "./OfferFooter";
import { OfferApprovalDetails } from "./ApprovalDialog";
import { toPrintableDateFormat } from "../util/TimeUtils";
import { OfferDetails } from "../generated/OfferApi";

// When updating this, update the same variable in the app as well.
export const TRAVEL_COST_PRICE = 5.87;

interface OfferProps {
  offerData: OfferDetails;
  onApproved: (approvalDetails: OfferApprovalDetails) => Promise<any>;
}

export default function Offer(props: OfferProps) {
  const data = props.offerData;
  const offerId = data.orderId + "/" + (data.lectureId + 1);

  useEffect(() => {
    if (offerId) {
      reportPageView("/v1/offer/" + offerId);
    }
  }, [offerId]);

  const approvalStatusBar = (
    <ApprovalStatusBar
      offerId={offerId}
      approvalDate={data.approvalTime}
      approvingContactName={data.approvingContactName}
    />
  );

  return (
    <PrintablePage statusBar={approvalStatusBar}>
      <div className={styles.icon}>
        <HMLogo className={styles.iconImage} />
      </div>

      <div className={styles.dateBox}>
        <div>{data.offerDate && toPrintableDateFormat(data.offerDate)}</div>
        <div>הצעת מחיר מספר {offerId}</div>
      </div>

      <div>לכבוד:</div>
      <div>{data.contactName}</div>
      <div>{data.organizationName}</div>

      <br />

      <div className={styles.subject}>{data.subject}</div>

      <br />

      <div className={styles.content}>{data.content}</div>

      <div className={styles.costTitle}>עלות ההדרכה:</div>
      <div className={styles.cost}>{data.offerCost}</div>
      <div>בהרצאה עם מעל מאה משתתפים תגבה תוספת של 50%.</div>
      <div>העלות אינה כוללת:</div>
      <li>מע״מ כחוק</li>
      {!data.noTravelCost && <li>נסיעות ({TRAVEL_COST_PRICE} ש״ח לק״מ)</li>}
      <li>חלוקת חומרים</li>
      <br />
      <div className={styles.paymentConditions}>
        תנאי תשלום: {data.paymentConditionInfo}
      </div>

      <div className={styles.sumUp}>
        <div>אשמח לעמוד לרשותך בכל עת</div>
        <div>חנן מלין</div>
      </div>

      <br />

      <ApprovalButton
        offerId={offerId}
        onApproved={props.onApproved}
        approved={!!data.approvalTime}
      />

      <br />

      <OfferFooter />
    </PrintablePage>
  );
}
