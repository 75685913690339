import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

const appVersion = process.env.REACT_APP_VERSION || "dev";
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.Fragment>
    <span id={appVersion} />
    <App />
  </React.Fragment>,
);
