/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApproveOfferRequest {
  /** @format int32 */
  lectureId?: number;
  token?: string;
  approvingUserFirstName?: string;
  approvingUserLastName?: string;
  approvingUserPhone?: string;
  approvingUserEmail?: string;
  approvingUserRole?: string;
  approvingOrganization?: string;
  lectureDates?: string;
}

export interface CourseAttendanceLecture {
  /** @format int32 */
  lectureId: number;
  topic?: string;
  /** @format date-time */
  date?: string;
}

export interface CourseAttendanceParticipant {
  /** @format int32 */
  participantId: number;
  firstName?: string;
  lastName?: string;
  organizationName?: string;
  lecturesAttending: Record<string, CourseAttendanceParticipantLecturesAttendingEnum>;
  scores: Record<string, string>;
}

export interface CreateOrderRequest {
  /** @format int32 */
  organizationId: number;
  isPublicCourseOrder: boolean;
  /** @format int32 */
  publicCourseId?: number;
}

export interface FutureLecture {
  /** @minLength 1 */
  type: string;
  /** @format int32 */
  id: number;
  /** @format date-time */
  lectureDate?: string;
  topic?: string;
  organizationName?: string;
}

export interface GenericResponse {
  success: boolean;
  errorMessage?: string;
}

export interface GetOrderResponse {
  order: Order;
  organization: Organization;
  orderLectures: OrderLecture[];
  publicCourse?: PublicCourse;
  publicCourseParticipants: PublicCourseParticipant[];
  isDuplicateOrganizationId: boolean;
}

export interface GetOrganizationResponse {
  organization?: Organization;
  organizationOrdersSummary: OrganizationOrderSummary[];
  isDuplicateOrganizationId: boolean;
}

export interface GetPaymentExpectedOrdersResponse {
  orders: PaymentExpectedOrder[];
  /** @minLength 1 */
  totalSum: string;
}

export interface GetPublicCOurseParticipantsResponse {
  /** @minLength 1 */
  courseName: string;
  lectures: CourseAttendanceLecture[];
  participantsAttendace: CourseAttendanceParticipant[];
}

export interface GetPublicCourseResponse {
  publicCourse: PublicCourse;
  /** @minLength 1 */
  courseSum: string;
  /** @minLength 1 */
  isoFee: string;
  lecturesSummary: PublicCourseLectureSummary[];
  participantsSummary: PublicCourseParticipantSummary[];
}

export interface InitialData {
  /** @format int32 */
  alertsCount: number;
  offeredLectures: Record<string, OfferedLecture>;
  organizationsIndex: OrganizationIndex[];
  ordersIndex: OrderIndex[];
  publicCoursesIndex: PublicCourseIndex[];
}

export interface LectureParticipantEmail {
  /** @minLength 1 */
  name: string;
  email?: string;
}

export interface OfferDetails {
  /** @format int32 */
  orderId: number;
  /** @format int32 */
  lectureId: number;
  offerDate?: string;
  subject?: string;
  content?: string;
  organizationName?: string;
  email?: string;
  contactName?: string;
  contactPhone?: string;
  paymentConditionInfo?: string;
  offerCost?: string;
  noTravelCost?: boolean;
  /** @format date-time */
  approvalTime?: string;
  approvingContactName?: string;
}

export interface OfferedLecture {
  topic?: string;
  subject?: string;
  content?: string;
  cost?: string;
}

export interface OpenPublicCourseOrder {
  /** @format int32 */
  orderId: number;
  courseName?: string;
  organizationName?: string;
  firstName?: string;
  lastName?: string;
  desiredLectures?: string;
}

export interface Order {
  /** @format int32 */
  id: number;
  /**
   * @format date-time
   * @minLength 1
   */
  createdDate: string;
  /**
   * @format date-time
   * @minLength 1
   */
  changedDate: string;
  /** @format int32 */
  organizationId: number;
  /** @default "offer" */
  status: OrderStatusEnum;
  alertCase?: string;
  /** @format date-time */
  alertStartTime?: string;
  alertMessage?: string;
  approved: boolean;
  rejected: boolean;
  rejectionReason?: string;
  rejectionDetails?: string;
  cancelled?: boolean;
  cancellationReason?: string;
  cancellationDetails?: string;
  /** @format int32 */
  contactId?: number;
  /** @format int32 */
  financialContactId?: number;
  isPublicCourseOrder: boolean;
  street?: string;
  streetNumber?: string;
  city?: string;
  location?: string;
  audienceType?: string;
  daySchedule?: string;
  projector?: boolean;
  soundSystem?: boolean;
  microphone?: boolean;
  parking?: boolean;
  sameAudience?: boolean;
  /** @format int32 */
  publicCourseId?: number;
  followUpRequired: boolean;
  /** @format date-time */
  followUpDate?: string;
  followUpDetails?: string;
  cost?: string;
  oneWayDistance?: string;
  travelExpenses?: string;
  extraCosts?: string;
  sum?: string;
  vat?: string;
  totalSum?: string;
  proformaInvoiceNumber?: string;
  /** @format date-time */
  proformaInvoiceDate?: string;
  /** @format date-time */
  expectedPayDate?: string;
  internalOrderNumber?: string;
  externalInvoiceSent?: boolean;
  taxInvoiceNumber?: string;
  /** @format date-time */
  taxInvoiceDate?: string;
  receiptNumber?: string;
  notes?: string;
}

export interface OrderIndex {
  /** @format int32 */
  id: number;
  /** @format int32 */
  organizationId?: number;
  /** @minLength 1 */
  organizationName: string;
  /** @minLength 1 */
  status: string;
}

export interface OrderLecture {
  /** @format int32 */
  id: number;
  /** @format int32 */
  orderId: number;
  isOnline: boolean;
  /** @format date-time */
  date?: string;
  topic?: string;
  startTime?: string;
  endTime?: string;
  duration?: string;
  audienceSize?: string;
  tie?: string;
  travelTime?: string;
  videoCallLink?: string;
  offerToken?: string;
  offerDate?: string;
  subject?: string;
  content?: string;
  paymentConditionInfo?: string;
  offerCost?: string;
  noTravelCost?: boolean;
  /** @format date-time */
  approvalTime?: string;
  approvingUserFirstName?: string;
  approvingUserLastName?: string;
  approvingUserPhone?: string;
  approvingUserEmail?: string;
  approvingUserRole?: string;
  approvingOrganization?: string;
  lectureDates?: string;
}

export interface OrderSummary {
  /** @format int32 */
  id: number;
  status?: string;
  organizationName?: string;
  topic?: string;
  /** @format date-time */
  lectureDate?: string;
  lecturesCount?: string;
}

export interface OrderWithAlert {
  /** @format int32 */
  orderId: number;
  /**
   * @format date-time
   * @minLength 1
   */
  createdDate: string;
  organizationName?: string;
  /** @minLength 1 */
  status: string;
  /** @format date-time */
  lectureDate?: string;
  /** @minLength 1 */
  alertMessage: string;
  /** @minLength 1 */
  alertCase: string;
}

export interface Organization {
  /** @format int32 */
  id: number;
  /**
   * @format date-time
   * @minLength 1
   */
  createdDate: string;
  /**
   * @format date-time
   * @minLength 1
   */
  changedDate: string;
  name?: string;
  companyId?: string;
  paymentConditions?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  internalOrderIdRequired: boolean;
  externalInvoiceHandler?: string;
  referralWay?: string;
  referralWayDetails?: string;
  notes?: string;
  contacts: OrganizationContact[];
}

export interface OrganizationContact {
  /** @format int32 */
  id: number;
  /** @format int32 */
  organizationId: number;
  firstName?: string;
  lastName?: string;
  phone1?: string;
  phone2?: string;
  email?: string;
  job?: string;
  phoneExtension?: string;
  hidden: boolean;
}

export interface OrganizationIndex {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  organizationName: string;
  companyId?: string;
}

export interface OrganizationOrderSummary {
  /** @format int32 */
  orderId: number;
  /** @format date-time */
  lectureDate?: string;
  topic?: string;
  lecturesCount?: string;
  status?: string;
  proformaInvoiceNumber?: string;
}

export interface PaymentExpectedOrder {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  status: string;
  organizationName?: string;
  /** @format date-time */
  lectureDate?: string;
  topic?: string;
  proformaInvoiceNumber?: string;
  /** @format date-time */
  expectedPayDate?: string;
  totalSum?: string;
  /** @format int32 */
  sum: number;
}

export interface PublicCourse {
  /** @format int32 */
  id: number;
  /**
   * @format date-time
   * @minLength 1
   */
  createdDate: string;
  /**
   * @format date-time
   * @minLength 1
   */
  changedDate: string;
  courseName?: string;
  onlineCourse: boolean;
  isoPayed?: boolean;
  publicCourseLectures: PublicCourseLecture[];
  courseContactName?: string;
  courseContactPhone?: string;
  courseCity?: string;
  courseLocation?: string;
  mealCost?: string;
  distanceCost?: string;
  projector?: boolean;
  soundSystem?: boolean;
  microphone?: boolean;
  parking?: boolean;
  roomsApproved?: boolean;
  printedMaterials?: boolean;
  printedCertificates?: boolean;
}

export interface PublicCourseAttendance {
  /** @format int32 */
  publicCourseParticipantId: number;
  /** @format int32 */
  publicCourseLectureId: number;
  /** @default "UNSET" */
  attendence?: PublicCourseAttendanceAttendenceEnum;
  score?: string;
}

export interface PublicCourseIndex {
  /** @format int32 */
  id: number;
  /** @minLength 1 */
  courseName: string;
}

export interface PublicCourseLecture {
  /** @format int32 */
  id: number;
  /** @format int32 */
  publicCourseId: number;
  /** @format date-time */
  date?: string;
  topic?: string;
  startTime?: string;
  endTime?: string;
  duration?: string;
  tie?: string;
  price?: string;
  videoCallLink?: string;
  roomCost?: string;
  pages?: string;
  guestLecturer?: boolean;
  guestLecturerName?: string;
  guestLecturerCost?: string;
}

export interface PublicCourseLectureSummary {
  /** @format int32 */
  lectureId: number;
  /** @format date-time */
  date?: string;
  /** @minLength 1 */
  topic: string;
  startTime?: string;
  endTime?: string;
  price?: string;
  /** @format int32 */
  participantCount: number;
  income?: string;
}

export interface PublicCourseParticipant {
  /** @format int32 */
  id: number;
  /** @format int32 */
  orderId: number;
  firstName?: string;
  lastName?: string;
  englishFirstName?: string;
  englishLastName?: string;
  idNumber?: string;
  email?: string;
  job?: string;
  phone?: string;
  isqMember?: boolean;
  participantCost?: string;
  certificateNeeded?: boolean;
  existingCertificates?: string;
  certificateDetails?: string;
  certificateSent?: boolean;
  desiredLectures?: string;
  lecturesAttending: PublicCourseAttendance[];
}

export interface PublicCourseParticipantSummary {
  /** @format int32 */
  participantId: number;
  /** @format int32 */
  orderId: number;
  approved: boolean;
  firstName?: string;
  lastName?: string;
  organizationName?: string;
  orderStatus?: string;
  proformaInvoiceNumber?: string;
  certificate?: string;
  attendedLectureIds: number[];
}

export interface PublicCourseSummary {
  /** @format int32 */
  id: number;
  courseName?: string;
  courseLocation?: string;
  /** @format date-time */
  startDate?: string;
  /** @format date-time */
  endDate?: string;
  /** @format int32 */
  participatnsCount: number;
}

export interface ReferralWayOrder {
  /** @format int32 */
  orderId: number;
  organizationName?: string;
  /** @minLength 1 */
  status: string;
  referralWay?: string;
  referralWayDetails?: string;
}

export interface SetContactRequest {
  organizationContact: OrganizationContact;
  /** @format int32 */
  organizationId: number;
}

export interface SetContactResponse {
  organization: Organization;
  /** @format int32 */
  contactId: number;
}

export interface SetScoresRequest {
  /** @format int32 */
  publicCourseParticipantId: number;
  /** @format int32 */
  publicCourseLectureId: number;
  /** @minLength 1 */
  score: string;
}

export interface UpdateOrderRequest {
  order: Order;
  orderLectures: OrderLecture[];
  publicCourseParticipants: PublicCourseParticipant[];
}

/** @default "UNSET" */
export enum CourseAttendanceParticipantLecturesAttendingEnum {
  UNSET = "UNSET",
  ATTENDED = "ATTENDED",
  NOT_ATTENDED = "NOT_ATTENDED",
}

/** @default "offer" */
export enum OrderStatusEnum {
  Offer = "offer",
  ApprovedOrder = "approvedOrder",
  IsExecuting = "isExecuting",
  Executed = "executed",
  WaitingPayment = "waitingPayment",
  Payed = "payed",
  Cancelled = "cancelled",
  Rejected = "rejected",
}

/** @default "UNSET" */
export enum PublicCourseAttendanceAttendenceEnum {
  UNSET = "UNSET",
  ATTENDED = "ATTENDED",
  NOT_ATTENDED = "NOT_ATTENDED",
}

/** @default "offer" */
export enum GetAllOrdersParamsStatusEnum {
  Offer = "offer",
  ApprovedOrder = "approvedOrder",
  IsExecuting = "isExecuting",
  Executed = "executed",
  WaitingPayment = "waitingPayment",
  Payed = "payed",
  Cancelled = "cancelled",
  Rejected = "rejected",
}

/** @default "offer" */
export enum GetReferralWaysParamsFilterStatusEnum {
  Offer = "offer",
  ApprovedOrder = "approvedOrder",
  IsExecuting = "isExecuting",
  Executed = "executed",
  WaitingPayment = "waitingPayment",
  Payed = "payed",
  Cancelled = "cancelled",
  Rejected = "rejected",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://localhost:7071/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Violet server APIs
 * @version 1.0
 * @baseUrl http://localhost:7071/api
 */
export class VioletApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  admin = {
    /**
     * No description
     *
     * @tags Admin
     * @name Test
     * @request GET:/Test
     */
    test: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/Test`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name Calendar
     * @request GET:/Calendar
     */
    calendar: (
      query: {
        /** Token */
        t: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/Calendar`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name AuthCheck
     * @request GET:/AuthCheck
     */
    authCheck: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/AuthCheck`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin
     * @name GetAuthStatus
     * @request GET:/GetAuthStatus
     */
    getAuthStatus: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/GetAuthStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  offeredLectures = {
    /**
     * No description
     *
     * @tags Offered Lectures
     * @name GetOfferedLectures
     * @request GET:/GetOfferedLectures
     */
    getOfferedLectures: (params: RequestParams = {}) =>
      this.request<Record<string, OfferedLecture>, any>({
        path: `/GetOfferedLectures`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offered Lectures
     * @name UpdateOfferedLecture
     * @request POST:/UpdateOfferedLecture
     */
    updateOfferedLecture: (body: OfferedLecture, params: RequestParams = {}) =>
      this.request<Record<string, OfferedLecture>, any>({
        path: `/UpdateOfferedLecture`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  organizations = {
    /**
     * No description
     *
     * @tags Organizations
     * @name DeleteOrganization
     * @request DELETE:/DeleteOrganization
     */
    deleteOrganization: (
      query: {
        /** @format int32 */
        organizationId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GenericResponse, any>({
        path: `/DeleteOrganization`,
        method: "DELETE",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name CreateOrganization
     * @request POST:/CreateOrganization
     */
    createOrganization: (body: Organization, params: RequestParams = {}) =>
      this.request<Organization, any>({
        path: `/CreateOrganization`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name GetOrganization
     * @request GET:/GetOrganization
     */
    getOrganization: (
      query: {
        /** @format int32 */
        organizationId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOrganizationResponse, any>({
        path: `/GetOrganization`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name SetContact
     * @request POST:/SetContact
     */
    setContact: (body: SetContactRequest, params: RequestParams = {}) =>
      this.request<SetContactResponse, any>({
        path: `/SetContact`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organizations
     * @name UpdateOrganization
     * @request POST:/UpdateOrganization
     */
    updateOrganization: (body: Organization, params: RequestParams = {}) =>
      this.request<Organization, any>({
        path: `/UpdateOrganization`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  orders = {
    /**
     * No description
     *
     * @tags Orders
     * @name MoveOrderToOrganization
     * @request POST:/MoveOrderToOrganization
     */
    moveOrderToOrganization: (
      query: {
        /** @format int32 */
        orderId: number;
        /** @format int32 */
        organizationId: number;
        /** @format int32 */
        contactId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GenericResponse, any>({
        path: `/MoveOrderToOrganization`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name GetFutureLectures
     * @request GET:/GetFutureLectures
     */
    getFutureLectures: (
      query?: {
        orderLecturesOnly?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<FutureLecture[], any>({
        path: `/GetFutureLectures`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name CreateOrder
     * @request POST:/CreateOrder
     */
    createOrder: (body: CreateOrderRequest, params: RequestParams = {}) =>
      this.request<Order, any>({
        path: `/CreateOrder`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name DeleteOrder
     * @request DELETE:/DeleteOrder
     */
    deleteOrder: (
      query: {
        /** @format int32 */
        orderId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GenericResponse, any>({
        path: `/DeleteOrder`,
        method: "DELETE",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name DuplicateOrder
     * @request POST:/DuplicateOrder
     */
    duplicateOrder: (
      query?: {
        /** @format int32 */
        orderId?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Order, any>({
        path: `/DuplicateOrder`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name GetAllOrders
     * @request GET:/GetAllOrders
     */
    getAllOrders: (
      query?: {
        /** @default "offer" */
        status?: GetAllOrdersParamsStatusEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderSummary[], any>({
        path: `/GetAllOrders`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name GetOrder
     * @request GET:/GetOrder
     */
    getOrder: (
      query?: {
        /** @format int32 */
        id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetOrderResponse, any>({
        path: `/GetOrder`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name GetOrdersWithAlert
     * @request GET:/GetOrdersWithAlert
     */
    getOrdersWithAlert: (params: RequestParams = {}) =>
      this.request<OrderWithAlert[], any>({
        path: `/GetOrdersWithAlert`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name GetPaymentExpectedOrders
     * @request GET:/GetPaymentExpectedOrders
     */
    getPaymentExpectedOrders: (
      query?: {
        includeApprovedOrders?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetPaymentExpectedOrdersResponse, any>({
        path: `/GetPaymentExpectedOrders`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name GetReferralWays
     * @request GET:/GetReferralWays
     */
    getReferralWays: (
      query?: {
        /** @format date-time */
        startDate?: string;
        /** @format date-time */
        endDate?: string;
        /** @default "offer" */
        filterStatus?: GetReferralWaysParamsFilterStatusEnum;
      },
      params: RequestParams = {},
    ) =>
      this.request<ReferralWayOrder[], any>({
        path: `/GetReferralWays`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name GetYaronOrders
     * @request GET:/GetYaronOrders
     */
    getYaronOrders: (params: RequestParams = {}) =>
      this.request<OrderSummary[], any>({
        path: `/GetYaronOrders`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name UpdateOrder
     * @request POST:/UpdateOrder
     */
    updateOrder: (body: UpdateOrderRequest, params: RequestParams = {}) =>
      this.request<GetOrderResponse, any>({
        path: `/UpdateOrder`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  initialData = {
    /**
     * No description
     *
     * @tags Initial Data
     * @name GetInitialData
     * @request GET:/GetInitialData
     */
    getInitialData: (params: RequestParams = {}) =>
      this.request<InitialData, any>({
        path: `/GetInitialData`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  content = {
    /**
     * No description
     *
     * @tags Content
     * @name GetLectureContent
     * @request GET:/GetLectureContent
     */
    getLectureContent: (
      query: {
        passCode: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/GetLectureContent`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Content
     * @name SetLectureContent
     * @request POST:/SetLectureContent
     */
    setLectureContent: (
      query: {
        passCode: string;
        downloadUrl: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/SetLectureContent`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),
  };
  offers = {
    /**
     * No description
     *
     * @tags Offers
     * @name ApproveOffer
     * @request POST:/ApproveOffer
     */
    approveOffer: (body: ApproveOfferRequest, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/ApproveOffer`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name GetOfferDetails
     * @request GET:/GetOfferDetails
     */
    getOfferDetails: (
      query: {
        /**
         * Lecture id
         * @format int32
         */
        l: number;
        /** Token */
        t: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OfferDetails, any>({
        path: `/GetOfferDetails`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Offers
     * @name RemoveOfferApproval
     * @request POST:/RemoveOfferApproval
     */
    removeOfferApproval: (
      query: {
        /** @format int32 */
        lectureId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/RemoveOfferApproval`,
        method: "POST",
        query: query,
        ...params,
      }),
  };
  publicCourses = {
    /**
     * No description
     *
     * @tags Public Courses
     * @name GetOpenPublicCourseOrders
     * @request GET:/GetOpenPublicCourseOrders
     */
    getOpenPublicCourseOrders: (params: RequestParams = {}) =>
      this.request<OpenPublicCourseOrder[], any>({
        path: `/GetOpenPublicCourseOrders`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public Courses
     * @name CreatePublicCourse
     * @request POST:/CreatePublicCourse
     */
    createPublicCourse: (body: PublicCourse, params: RequestParams = {}) =>
      this.request<PublicCourse, any>({
        path: `/CreatePublicCourse`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public Courses
     * @name GetAllPublicCourses
     * @request GET:/GetAllPublicCourses
     */
    getAllPublicCourses: (params: RequestParams = {}) =>
      this.request<PublicCourseSummary[], any>({
        path: `/GetAllPublicCourses`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public Courses
     * @name GetLectureParticipantsEmails
     * @request GET:/GetLectureParticipantsEmails
     */
    getLectureParticipantsEmails: (
      query: {
        /** @format int32 */
        lectureId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<LectureParticipantEmail[], any>({
        path: `/GetLectureParticipantsEmails`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public Courses
     * @name GetPublicCourse
     * @request GET:/GetPublicCourse
     */
    getPublicCourse: (
      query: {
        /** @format int32 */
        publicCourseId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetPublicCourseResponse, any>({
        path: `/GetPublicCourse`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public Courses
     * @name GetPublicCourseParticipants
     * @request GET:/GetPublicCourseParticipants
     */
    getPublicCourseParticipants: (
      query: {
        /** @format int32 */
        publicCourseId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetPublicCOurseParticipantsResponse, any>({
        path: `/GetPublicCourseParticipants`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public Courses
     * @name MarkAttendance
     * @request POST:/MarkAttendance
     */
    markAttendance: (body: PublicCourseAttendance, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/MarkAttendance`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public Courses
     * @name SetScores
     * @request POST:/SetScores
     */
    setScores: (body: SetScoresRequest[], params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/SetScores`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Public Courses
     * @name UpdatePublicCourse
     * @request POST:/UpdatePublicCourse
     */
    updatePublicCourse: (body: PublicCourse, params: RequestParams = {}) =>
      this.request<PublicCourse, any>({
        path: `/UpdatePublicCourse`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
