export function toPrintableDateFormat(dateStr: string | Date) {
  const date = new Date(dateStr);
  if (date.getHours() >= 20) date.setHours(date.getHours() + 4); // Increment day by one for time zone change

  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();

  return day + "/" + month + "/" + year;
}

function pad(num: number) {
  if (num >= 10) return num.toString();
  return "0" + num.toString();
}
